<template>
  <div>
    <process-design ref="processDesign" />
  </div>
</template>

<script>
import ProcessDesign from '@/views/flowable/ProcessDesign.vue'
export default {
  name: 'FormProcessDesign',
  components: {
    ProcessDesign
  }
}
</script>

<style></style>
